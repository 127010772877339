
import { Component, Vue } from "vue-property-decorator";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main.vue';
import XDialog from "@/components/x-dialog/x-dialog.vue";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_Supplement from "@/api/supplement";
import * as API_order from "@/api/order";
import { mergeSkuList } from "@/utils";
import * as Foundation from "../../../../ui-utils/Foundation";
import { getSellerShopExtAuth } from '@/api/account'
@Component({
  name: "supplementDetail",
  components: {
    EnTableLayout,
    XDialog
  },
  filters: {
    trade_status(status) {
      switch (status) {
        case 'NEW':
          return '新订单'
        case 'CONFIRM':
          return '待付款'
        case 'PAID_OFF':
          return '待发货'
        case 'SHIPPED':
          return '已发货'
        case 'ROG':
          return '已收货'
        case 'COMPLETED':
          return '已完成'
        case 'COMPLETE':
          return '已完成'
        case 'CANCELLED':
          return '已取消'
      }
    },
  }
})

export default class supplementDetail extends Vue {
  seller_id = '';//企业id
  pickupGift = 1;//1 普通商品  2 自提商品  3 邮寄商品  (蛋糕商品不允许补单)
  loading = false;
  sn = this.$route.params.sn;
  orderDetail: any = {};
  fhProductList: any = [];
  productList: any = [];
  rowKeysList: any = [];
  /**卡券详情 */
  cardInfoTh: any = '';
  comboInfo: any = {};

  supplementShopId: string = '';

  supplementGoodsList: any = [];
  /** 补单 */
  supplementDialog = $xDialog.create({
    title: '形成补单订单',
    width: '50vw',
    showClose: true,
    beforeConfirm: () => this.handlerConfirmSupplementDialog(),
    beforeCancel: () => this.handlerCloseSupplementDialog(),
    beforeClose: () => this.handlerCloseSupplementDialog()
  });
  supplementForm: any = {
    "addr_id": '',
    "card_key": "",
    "member_id": 0,
    "remark": "",
    "shop_id": 0,
    "source_trade_sn": "",
    "trade_item_param_volist": [
      {
        "num": 0,
        "sku_id": 0,
      }
    ]
  };
  selectGoodsList: any = [];
  display = false;
  multiple = true;
  selectGoods = {};

  userAddressList: any = [];
  selectAddressId;

  /** 添加收货地址 */
  addAddressDialog = $xDialog.create({
    title: '新增收货地址',
    width: '35vw',
    showClose: false,
    beforeCancel: () => this.handlerAddAddressDialog(2),
    beforeConfirm: () => this.handlerAddAddressConfirm()
  });
  addAddressForm: any = {
    name: '',
    addr: '',
    mobile: '',
    def_addr: 0,
    region: '',
    shop_id: ''
  };
  addAddressRules = {
    name: [
      { required: true, message: '请输入收货人', trigger: 'blur' }
    ],
    addr: [
      { required: true, message: '请输入详细地址', trigger: 'blur' }
    ],
    mobile: [
      { required: true, message: '请输入手机号', trigger: 'blur' }
    ],
  }

  mounted() {
    this.getSupplementDetail();
  }

  /** 订单状态转换 */
  fService_status(status) {
    switch (status) {
      case "NOT_APPLY":
        return "未申请";
      case "APPLY":
        return "已申请";
      case "PASS":
        return "审核通过";
      case "REFUSE":
        return "审核未通过";
      case "EXPIRED":
        return "已失效或不允许申请售后";
      case "REFUNDFAIL":
        return "退款失败";
      case "COMPLETED":
        return "完成";
      case "WAIT_SEND_BACK":
        return "待寄回";
      case "SEND_BACK":
        return "买家寄回";
      case "SELLER_SHIP":
        return "卖家发货";
      case "REFUSE_SHIP":
        return "卖家拒绝发货";
      default:
        return "";
    }
  }
  showMallType() {
    return this.orderDetail?.order[0]?.sku_list[0]?.shop_id == this.orderDetail?.order[0]?.sku_list[0]?.seller_id
  }
  /**查询订单详情卡券信息 */
  getOrdergetCardInfoTh(params) {
    API_order.getComboInfo({ card_code: params.card_code, shop_id: params.shop_id }).then((res) => {
      this.cardInfoTh = res;
    })
  }

  async getSupplementDetail() {
    const shop = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
    this.seller_id = shop.shop_id;
    this.loading = true;
    API_order.getTradeInfo(this.sn).then(res => {
      //查询卡券信息
      res.card_code && this.getOrdergetCardInfoTh(res)
      this.loading = false;
      this.productList = [];
      res.mall_type == 5 ? res.card_key = '1' : res.card_key
      this.orderDetail = res;
      this.supplementShopId = res.shop_id;
      this.orderDetail['payment_method_name'] = this.orderDetail.payment_method_name.replace(/,/g, '+')
      res.order.forEach(item => {
        item.sku_list.forEach(i => {
          i.ext_order_id = item.ext_order_id
          i.ext_order_no = item.ext_order_no
          this.productList.push(i)
        })
      });
      //判断补单商品是普通商品 还是商城自提商品或者商城邮寄商品delivery_method=1 邮寄商品  delivery_method=2自提商品
      if (this.productList[0].seller_id == this.productList[0].shop_id) {
        this.pickupGift = res.delivery_method == 1 ? 3 : 2
      } else {
        this.pickupGift = 1
      }
      if (res.mall_type == 4) {
        //获取卡券信息
        API_order.getComboInfo({ card_code: res.card_code, shop_id: res.shop_id }).then(response => {
          this.comboInfo = response;
        })
      }
      // 获取补单记录
      this.getSupplementRecord()
      // 获取收货地址列表
      const _params = {
        member_id: res.member_id,
        card_key: res.mall_type == 4 ? res.card_key : '1',
        shop_id: res.shop_id
      }
      API_Supplement.getMemberAddressList(_params).then(res => {
        this.userAddressList = res;
        this.selectAddressId = res.filter(item => item.def_addr === 0)[0]?.addr_id ?? res[0].addr_id;
      })
    })
  }

  /** 获取补单记录 */
  getSupplementRecord() {
    API_Supplement.getSupplementRecord({ trade_sn: this.sn }).then(res => {
      if (res.data) {
        this.supplementGoodsList = mergeSkuList(res.data, 1);
      }
    })
  }

  /** 补单商品信息列表 */
  orderSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex >= 2 && columnIndex <= 5) {
      return {
        rowspan: 1,
        colspan: 1
      };
    } else {
      return row.span;
    }
  }

  handleOpenSupplementDialog() {
    this.supplementForm.member_id = this.orderDetail.member_id;
    this.supplementForm.shop_id = this.orderDetail.shop_id;
    this.supplementForm.card_key = this.orderDetail.card_key;
    this.supplementForm.source_trade_sn = this.sn;
    this.supplementDialog.display()
  }

  handlerCloseSupplementDialog() {
    this.supplementDialog.dismiss();
    return false;
  }

  handlerConfirmSupplementDialog() {
    this.supplementForm.addr_id = this.selectAddressId;
    this.supplementForm.trade_item_param_volist = this.selectGoodsList.map(item => {
      return {
        num: item.goods_num,
        sku_id: item.goods_id
      }
    })
    let supplementForm = {
      ...this.supplementForm
    }
    if (this.orderDetail.mall_type == 5) delete supplementForm.card_key;
    API_Supplement.createSupplementOrder(supplementForm).then(res => {
      if (res.trade_sn) {
        this.$message.success('补单成功');
        this.supplementDialog.dismiss();
        this.getSupplementRecord();
      }
    })
    return false;
  }

  handleOpenGoodsPicker() {
    this.display = true;
  }

  handlerCloseGoodsPicker() {
    this.display = false;
  }

  handleSelectGoods(item) {
    this.selectGoodsList = item;
    this.selectGoodsList.forEach((goods, index) => {
      if (!goods.goods_num) {
        this.$set(this.selectGoodsList[index], 'goods_num', 1)
      }
    })
  }

  handleDeleteSelectGoods(index) {
    this.selectGoodsList.splice(index, 1)
  }

  handlerSelectAddress(addr_id) {
    this.selectAddressId = addr_id;
    this.$forceUpdate();
  }

  /** 收货地址添加 */
  handlerAddAddressDialog(type) {
    if (type === 1) {
      this.addAddressForm = {
        name: '',
        addr: '',
        mobile: '',
        def_addr: 0,
        region: '',
        shop_id: ''
      }
      this.addAddressForm['shop_id'] = this.orderDetail.shop_id;
      this.addAddressDialog.display()
    } else {
      this.addAddressDialog.dismiss();
    }
    return false;
  }

  /** 地址选择change方法 */
  handlerAddressSelectorChange(object) {
    this.addAddressForm.region = object.last_id;
  }

  handlerAddAddressConfirm() {
    this.addAddressForm['member_id'] = this.orderDetail.member_id;
    (this.$refs.addAddressForm as any).validate(valid => {
      if (valid) {
        API_Supplement.addMemberAddress(this.addAddressForm, this.orderDetail.card_key).then(res => {
          if (res.name) {
            this.$message.success('添加成功');
            this.addAddressDialog.dismiss();
            this.getSupplementDetail();
          }
        })
      } else {
        this.$message.error('请检查表单！！！')
      }
    })
    return false;
  }

  /** 取消订单 */
  handlerCancelTrade(row) {
    console.log(row)
    this.$confirm('确认取消订单吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      API_Supplement.cancelSupplement({ trade_sn: row.trade_sn, reason: '客服取消' }).then(res => {
        this.$message.success(res.statusMsg);
        this.getSupplementDetail();
      })
    }).catch(() => {
      console.log()
    });
  }

  /** 查看、操作订单 */
  handleOperateOrder(item) {
    this.$router.push({
      path: `/order/order-list/detail/${item.trade_sn}?type=1`
    });
  }

}
